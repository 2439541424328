var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Ürün")]),_c('div',{staticClass:"card-header-actions"},[_c('small',{domProps:{"textContent":_vm._s(_vm.modeProduct === 'new' ? 'Yeni' : 'Düzenle')}})])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"10"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"value":_vm.formData.categoryId,"label":"Kategorisi","placeholder":"Kategorisi","options":_vm.categories.map(function (p) { return ({
                        value: p.id,
                        label: p.title
                      }); })},on:{"update:value":function($event){return _vm.$set(_vm.formData, "categoryId", $event)}}})],1),_c('CCol',{attrs:{"sm":"8"}},[_c('CInput',{attrs:{"value":_vm.formData.title,"label":"Ürün Adı","placeholder":"Ürün Adı"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "title", $event)}}})],1),_c('CCol',{attrs:{"sm":"4"}},[_c('CInput',{attrs:{"value":_vm.formData.price,"label":"Fiyatı","placeholder":"Fiyatı"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "price", $event)}}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CTextarea',{attrs:{"value":_vm.formData.description,"label":"Açıklaması","placeholder":"Açıklaması","rows":"10"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "description", $event)}}})],1)],1)],1)],1),_c('hr'),_c('CRow',[_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[(_vm.modeProduct !== 'new')?_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary"},on:{"click":function($event){return _vm.updateProduct(_vm.id)}}},[_vm._v("Kaydet")]):_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"primary"},on:{"click":function($event){return _vm.createProduct()}}},[_vm._v("Yeni Ekle")]),_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.cleanProduct()}}},[_vm._v("Temizle")])],1)],1)],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Ürünler")])]),_c('CCardBody',[_c('CDataTable',{attrs:{"columnFilter":true,"hover":true,"striped":true,"items":_vm.companyProducts,"fields":[
              {
                key: 'id',
                label: 'ID',
              },
              {
                key: 'categoryStr',
                label: 'Kategorisi',
              },
              {
                key: 'title',
                label: 'Ürün Adı',
              },
              {
                key: 'description',
                label: 'Açıklama',
              },
              {
                key: 'price',
                label: 'Fiyat',
              },
              {
                key: 'actions',
                label: 'Aksiyonlar',
              } ],"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"m-1",attrs:{"size":"sm","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v("Düzenle")]),_c('CButton',{staticClass:"m-1",attrs:{"size":"sm","variant":"outline","color":"danger"},on:{"click":function($event){return _vm.removeProduct(item.id)}}},[_vm._v("Sil")]),_c('CButton',{staticClass:"m-1",attrs:{"size":"sm","variant":"outline","color":"warning"},on:{"click":function($event){return _vm.$router.push({ path: ("product-locales/" + (item.id)) })}}},[_vm._v("Dilleri Düzenle")])],1)]}},{key:"categoryStr",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.category.title)+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }